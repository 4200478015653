export default {
    methods: {
        hideText(string, length, isOn) {
            if(isOn && string && string.length > length) {
                string = string.substring(0, length) + '...'
            }
            
            return string
        },
    }
}