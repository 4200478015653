export default {
    methods: {
        roundMaxDecimals(number, decimals) {
            if(number) {
                number = +(Math.round(number + `e+${decimals}`) + `e-${decimals}`)
            }

            return number
        }
    }
}